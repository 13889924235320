import React, { Component, Fragment } from 'react';
import { Nav, NavItem, Navbar, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../api';
import '../css/nav.css';

class Navigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
	}

	render() {
		const { open } = this.state;
		const { user } = this.props;
		return <Navbar color="light" light expand="md">
			<NavbarBrand href="/">
				<img src="/logo.png" alt="OMG" />
			</NavbarBrand>
			<NavbarToggler onClick={() => this.setState({ open: !open })} />
			<Collapse isOpen={open} navbar>
				<Nav className="ml-auto" navbar>
					{user && <Fragment>
						<NavItem>
							<Link to="/datasets" className="nav-link">Bestanden</Link>
						</NavItem>
						{user.admin && <NavItem>
							<Link to="/mailings" className="nav-link">Financieel overzicht intern</Link>
						</NavItem>}
						<NavItem>
							<Link to="/finances" className="nav-link">Financiën</Link>
						</NavItem>
						<NavItem>
							<Link to="/stats" className="nav-link">Statistieken</Link>
						</NavItem>
						{!user.admin && <NavItem>
							<Link to="/contact" className="nav-link">Contact</Link>
						</NavItem>}
						{user.admin && <NavItem>
							<Link to="/invoicing" className="nav-link">Facturatie</Link>
						</NavItem>}
						{user.admin && <NavItem>
							<Link to="/corrections" className="nav-link">Correcties</Link>
						</NavItem>}
						<NavItem className="pl-2">
							<Link to={user.admin ? '/admin' : '/'} className="nav-link" onClick={() => logout()}>
								Log uit: {user.name} {!user.admin && <small>({user.partner})</small>}
							</Link>
						</NavItem>
					</Fragment>}
				</Nav>
			</Collapse>
		</Navbar>;
	}
}

export default connect(({ user }) => {
	return { user };
})(Navigation);
