import React, { Fragment } from 'react';
import { Table, Button } from 'reactstrap';
import NumberCol from './NumberCol';

export default function Invoicing({ mailings, selection, format, PRECISION, getCsv }) {
	return <Fragment>
		<p><Button className="omg-btn blue" onClick={e => {
			e.preventDefault();
			getCsv('invoicing');
		}}>Download (CSV)</Button></p>
		<Table>
			<thead className="sticky-thead">
				<tr>
					<th>Crediteurnummer</th>
					<th>Naam</th>
					<th>Bestand</th>
					<th>Cred</th>
					<th>Land</th>
					<th>Grootboekrekening</th>
					<th>BTW code</th>
					<th className="text-right">Bedrag ex BTW</th>
					<th className="text-right">BTW</th>
					<th className="text-right">BTW bedrag</th>
					<th className="text-right">Bedrag incl BTW</th>
				</tr>
			</thead>
			{selection.length > 0 && <tbody>
				{selection.map(line => {
					return <tr key={line.id}>
						<td>{line.debitorNumber}</td>
						<td>{line.partner}</td>
						<td>{line.dataset.name}</td>
						<td>CRED</td>
						<td>{line.country}</td>
						<td>{line.ledger}</td>
						<td>{line.vatCode}</td>
						<NumberCol format={format} value={line.gross / PRECISION} currency />
						<NumberCol format={format} value={line.vatPercentage} percentage />
						<NumberCol format={format} value={line.vatAmount / PRECISION} currency />
						<NumberCol format={format} value={line.net / PRECISION} currency />
					</tr>;
				})}
			</tbody>}
		</Table>
		{mailings ?
			selection.length === 0 && <p>Geen verzendingen gevonden</p>
			: <p>Loading...</p>}
	</Fragment>;
}
