import React, { Fragment, useEffect, useState } from 'react';
import { Form, FormGroup, Input, Button, Alert, Label } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CorrectionInput from './CorrectionInput';
import { getCommission } from '../../api';
import vars from '../../utils/variables';

const round = value => Number(Math.round(value+'e3')+'e-3');

export default function CorrectionForm({ 
	title,
	partners, 
	mailings, 
	filter, 
	handleSubmit, 
	correction, 
	setCorrection,
	error,
	setError,
	submitError
}) {
	const [commission, setCommission] = useState(undefined);
	const history = useHistory();

	const setCommissionOnEdit = () => {
		if(correction.partner && correction.dataset && correction.date){
			getCommission(correction.partner, correction.dataset, correction.date).then(data => {
				// commission is either null or contains a value, if null, no commission was found
				setCommission(data.commission);
			}).catch(setError);
		}
	};

	useEffect(setCommissionOnEdit, []);
	
	const updateCommission = (partner, dataset, date) => {
		getCommission(partner, dataset, date).then(data => {
			// commission is either null or contains a value, if null, no commission was found
			setCommission(data.commission);
		}).catch(setError);
	};

	const handlePartner = value => {
		filter(value);
		// don't change anything if the same partner was chosen
		if(correction.partner !== value){
			// set partner value
			setCorrection({...correction, partner: value, dataset: ''});
			// set commission to undefined again, as a new partner also sets dataset to empty
			setCommission(undefined);
		}
	};
	
	// description can just be set
	const handleDescr = value => setCorrection({...correction, description: value});

	const handleDataset = value => {
		// if a partner is chosen
		if(value && value !== correction.dataset && correction.partner){
			// set dataset and update the commission
			setCorrection({...correction, dataset: value});
			updateCommission(correction.partner, value, correction.date);
		}
	};

	const handleDate = d => {
		// set the new date
		setCorrection({...correction, date: d});
			
		// if date was changed before partner and dataset was set, don't do anything. Otherwise update the commission
		if(correction.partner && correction.dataset){
			updateCommission(correction.partner, correction.dataset, d);
		}
	};

	const commValue = commission?? 1;

	const handleGross = value => {
		value = +value || 0;
		setCorrection({...correction, gross: value, net: round(value * commValue)});
	};
	const handleNet = value => {
		value = +value || 0;
		setCorrection({...correction, net: value, gross: round(value / commValue)});
	};

	const handleBack = () => history.push('/corrections');

	if(!correction){
		return <p>loading...</p>;
	}

	return <section className="container-fluid">
		<Form className="mb-3" onSubmit={e => handleSubmit(e, correction)}>
			<div className="form-inline justify-content-between">
				<h2> {title} </h2>

				<Button className="btn omg-btn red font-weight-bold" onClick={handleBack}> Cancel </Button>
			</div>

			{
				error ?
					<Alert color="danger">
							Er is iets onverwachts fout gegaan, probeer het later opnieuw.<br/>
						{error.message}
					</Alert>
					:
					<Fragment>
						<CorrectionInput name="partner" title="Partner" type="select" handleChange={handlePartner} correction={correction} options={partners}/>
						<CorrectionInput name="dataset" title="Bestand" type="select" handleChange={handleDataset} correction={correction} options={mailings}/>
						<CorrectionInput name="date" title="Datum" type="date">
							<DatePicker dateFormat={'dd-MM-yyyy'} className="form-control" onChange={handleDate} selected={correction.date} required/>
						</CorrectionInput>
						<CorrectionInput name="description" title="Omschrijving" type="textarea" handleChange={handleDescr} correction={correction} required={false}/>
						<CorrectionInput name="gross" title="Bruto" type="number" handleChange={handleGross} correction={correction}/>
						<CorrectionInput name="net" title="Netto" type="number" handleChange={handleNet} correction={correction}/>
						
						<Alert isOpen={!!(correction.partner && correction.dataset && commission === null)} color="warning">
							Er is geen commissie gevonden voor het opgegeven bestand en de datum!
						</Alert>

						{
							commission && 
								<Label>Commission: {commission * vars.PERCENT}%</Label>
						}
						
						<Alert isOpen={submitError} color="danger">
								Er is iets misgegaan tijdens het opslaan van de correctie, probeer het later nog een keer.
						</Alert>
				
						<FormGroup>
							<Input value="Opslaan" type="submit" className="btn omg-btn blue"/>
						</FormGroup>
					</Fragment>
			}
		</Form>
	</section>;
}
