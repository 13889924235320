import React, { Fragment } from 'react';
import { Label, FormGroup, Input } from 'reactstrap';

const ONE_HUNDRED = 100;

export default function CorrectionInput({name, title, type, handleChange, correction, children, options, required=true}) {
	return <FormGroup>
		{
			children?
				<div className="d-flex flex-column">
					<Label for={name}>{title}</Label>
					{children}
				</div>
				:
				<Fragment>
					<Label for={name}>{title}</Label>
					<Input 
						type={type} 
						name={name} 
						id={name} 
						onChange={e => handleChange(e.target.value)} 
						value={correction[name]} 
						required={required} 
						maxLength={type === 'textarea'? ONE_HUNDRED : null}
					>
						{options && options.map((opt, index) => <option key={index} value={opt.id? opt.id : opt}>{opt.name? opt.name : opt}</option>)}
					</Input>
				</Fragment>
		}
	</FormGroup>;
}
