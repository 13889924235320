import axios from 'axios';
import STATUS_CODES from 'http-status-codes';
import store from './store';

const client = axios.create({
	baseURL: '/api'
});

function setAccount({ data: account, status }) {
	if(status === STATUS_CODES.PARTIAL_CONTENT) {
		return true;
	}
	store.dispatch({ type: 'login', account });
}

export function checkLogin() {
	client.get('/auth/me').then(setAccount).catch(() => undefined);
}

export function login(username, password, admin, token) {
	return client.post('/auth/login', { username, password, admin, token }).then(setAccount);
}

export function logout() {
	return client.post('/auth/logout').then(() => {
		setAccount({ data: null });
	}).catch(err => {
		console.error(err);
	});
}

export function requestPasswordReset(username) {
	return client.post('/auth/reset/mail', { username });
}

export function checkToken(token) {
	return client.get(`/auth/reset/${encodeURIComponent(token)}`);
}

export function resetPassword(token, password) {
	return client.post(`/auth/reset/${encodeURIComponent(token)}`, { password });
}

export function getDatasets() {
	return client.get('/datasets').then(res => res.data);
}

export function getMailings(start, end) {
	return client.get(`/mailings/${encodeURIComponent(start.toISOString())}/${encodeURIComponent(end.toISOString())}`).then(res => res.data);
}

export function getFinances(start, end) {
	return client.get(`/mailings/finances/${encodeURIComponent(start.toISOString())}/${encodeURIComponent(end.toISOString())}`).then(res => res.data);
}

export function getStats(start, end) {
	return client.get(`/mailings/stats/${encodeURIComponent(start.toISOString())}/${encodeURIComponent(end.toISOString())}`).then(res => res.data);
}

export function getInvoicing(start, end) {
	return client.get(`/mailings/invoicing/${encodeURIComponent(start.toISOString())}/${encodeURIComponent(end.toISOString())}`).then(res => res.data);
}

export function getFilters() {
	return client.get('/mailings/filters').then(res => res.data);
}

export function getVersion() {
	return client.get('/version').then(res => res.data);
}

export function getCorrectionOptions() {
	return client.get('/corrections/options').then(res => res.data);
}

export function getCorrections(start, end) {
	return client.get(`/corrections/${encodeURIComponent(start.toISOString())}/${encodeURIComponent(end.toISOString())}`).then(res => res.data);
}

export function getCorrection(id) {
	return client.get(`/corrections/${encodeURIComponent(id)}`).then(res => res.data);
}

export function saveCorrection(correction) {
	return client.post('/corrections', correction).then(res => res.data);
}

export function updateCorrection(id, correction) {
	return client.put(`/corrections/${encodeURIComponent(id)}`, correction).then(res => res.data);
}

export function deleteCorrection(id) {
	return client.delete(`/corrections/${encodeURIComponent(id)}`).then(res => res.data);
}

export function getCommission(partner, mailing, date) {
	return client.get(`/corrections/commission/${encodeURIComponent(partner)}/${encodeURIComponent(mailing)}/${encodeURIComponent(date)}`).then(res => res.data);
}
