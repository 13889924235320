import React, { Fragment } from 'react';
import moment from 'moment';
import NumberCol from '../NumberCol';
import { sumColumn } from '../../utils/utils';

const corrCols = 4;

export default function CorrectionRows({ admin, selection, corrections, corrSelection, format, PRECISION, grossSpan, netSpan }) {
	const c = corrSelection.concat(selection);
	const grossTotal = sumColumn('gross', c) / PRECISION;
	const netTotal = sumColumn('net', c) / PRECISION;
	const totalSpan = corrCols + grossSpan - (admin? 0 : 1);
	return <Fragment>
		<tr>
			<th colSpan={totalSpan + netSpan + 1}><h2>Correcties</h2></th>
		</tr>

		<tr>
			{admin && <th>Partner</th>}
			<th colSpan={2}>Omschrijving</th>
			<th>Bestand</th>
			<th>Datum</th>
			<th colSpan={grossSpan} className="text-right">Bruto</th>
			<th colSpan={netSpan} className="text-right">Netto</th>
		</tr>
		{
			corrSelection.length > 0 ?
				<Fragment>
					{
						corrections.map((line, index) => {
							return <tr key={index}>
								{admin && <td>{line.partner}</td>}
								<td colSpan={2}>{line.description}</td>
								<td>{line.dataset.name}</td>
								<td>{moment(line.date).format('DD-MM-YYYY')}</td>
								<NumberCol colSpan={grossSpan} format={format} value={line.gross / PRECISION} currency/>
								<NumberCol colSpan={netSpan} format={format} value={line.net / PRECISION} currency/>
							</tr>;
						})
					}

					<tr>
						<th colSpan={totalSpan}>Correcties Totaal</th>
						<NumberCol className="font-weight-bold text-right" format={format} value={sumColumn('gross', corrSelection) / PRECISION} currency />
						<NumberCol className="font-weight-bold text-right" colSpan={netSpan} format={format} value={sumColumn('net', corrSelection) / PRECISION} currency />
					</tr>

					<tr className="bg-light">
						<th colSpan={totalSpan}>Totaal over geselecteerde periode</th>
						<NumberCol className="font-weight-bold text-right" format={format} value={grossTotal} currency/>
						<NumberCol className="font-weight-bold text-right" colSpan={netSpan} format={format} value={netTotal} currency/>
					</tr>
				</Fragment>
				:
				<tr>
					<td colSpan={totalSpan + netSpan + 1}>{corrections ? 'Geen correcties gevonden' : 'Loading...'}</td>
				</tr>
		}
	</Fragment>;
}
