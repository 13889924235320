import React, { Component } from 'react';
import { Form, FormGroup, Input, Alert, Row, Col, Button, ButtonGroup } from 'reactstrap';
import { login, getVersion, requestPasswordReset } from '../api';
import { version as webVersion } from '../../package.json';

const TOKEN_LENGTH = 6;

export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			error: null,
			version: null,
			token: null,
			reset: false
		};
		this.submit = this.submit.bind(this);
		this.handleTokenChange = this.handleTokenChange.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.reset = this.reset.bind(this);
	}

	componentDidMount() {
		if(this.props.admin) {
			getVersion().then(version => {
				this.setState({ version });
			}).catch(err => {
				console.warn('Failed to get version', err);
			});
		}
	}

	submit(e, token) {
		e.preventDefault();
		const { username, password } = this.state;
		this.setState({ error: '' });
		if(username && password) {
			login(username, password, this.props.admin, token).then(res => {
				if(res) {
					this.setState({ token: '' });
				}
			}).catch(err => {
				this.setState({ error: err.response?.data?.message || err.message });
			});
		}
	}

	handleTokenChange(e) {
		const newValue = e.target.value;
		this.setState({ token: newValue });
		if(newValue.length === TOKEN_LENGTH) {
			this.submit(e, newValue);
		}
	}

	handleBack() {
		this.setState({
			username: '',
			password: '',
			error: null,
			version: null,
			token: null
		});
	}

	reset(e) {
		e.preventDefault();
		this.setState({ error: '' });
		requestPasswordReset(this.state.username).then(() => {
			this.setState({ reset: true });
		}).catch(err => {
			this.setState({ error: err.response?.data?.message || err.message });
		});
	}

	render() {
		const { admin } = this.props;
		const { username, password, error, version, token, reset } = this.state;
		if(reset) {
			return <section className="container">
				<Alert color="success">An email with instructions to reset your password has been sent.</Alert>
			</section>;
		}
		return <section className="container">
			{admin && <h2>MPR Employee Login</h2>}
			{!admin && <h2>MPR Login</h2>}
			{error && <Alert color="danger">{error}</Alert>}
			{!error && token !== null && <Alert color="info">Two factor authentication is enabled, please enter your token.</Alert>}
			{token !== null?
				<>
					<FormGroup>
						<Input placeholder="Enter your 2fa token" value={token} type="text" onChange={this.handleTokenChange} autoFocus="autofocus" />
					</FormGroup>
					<Input type="button" value="go back" className="btn omg-btn blue" onClick={this.handleBack} />
				</>
				:
				<Form onSubmit={this.submit}>
					<FormGroup>
						<Input placeholder="Username" value={username} type="text" onChange={e => this.setState({ username: e.target.value })} autoFocus="autofocus" />
					</FormGroup>
					<FormGroup>
						<Input placeholder="Password" value={password} type="password" onChange={e => this.setState({ password: e.target.value })} />
					</FormGroup>
					{admin ? <Input type="submit" value="Login" className="btn omg-btn blue" /> : <ButtonGroup className="row d-block mt-1 mx-0">
						<Button color="light" className="col-6 col-sm-4 col-lg-2" disabled={!username} onClick={this.reset}>Reset password</Button>
						<Input type="submit" value="Login" className="btn omg-btn blue col-6 col-sm-8 col-lg-10" disabled={!username || !password} />
					</ButtonGroup>}
				</Form>
			}
			{admin && version && <div className="small">
				<Row>
					<Col xs={6}>Website:</Col>
					<Col className="text-right" xs={6}>{webVersion || 'Unknown'}</Col>
				</Row>
				<Row>
					<Col xs={6}>API:</Col>
					<Col className="text-right" xs={6}>{version.api || 'Unknown'}</Col>
				</Row>
			</div>}
		</section>;
	}
}
