import React, { Component, Fragment } from 'react';
import Navigation from './Navigation';
import Content from './Content';
import { checkLogin } from '../api';

export default class App extends Component {
	componentDidMount() {
		checkLogin();
	}

	render() {
		return <Fragment>
			<Navigation />
			<Content />
		</Fragment>;
	}
}
