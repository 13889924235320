import moment from 'moment';
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function DeleteModal({ handleDelete, correction, isOpen, toggle}) {
	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalHeader toggle={toggle}>Modal title</ModalHeader>
			<ModalBody>
        Weet je zeker dat je deze correctie wilt verwijderen?
				{
					correction && <ul>
						<li>ID: {correction._id}</li>
						<li>Partner: {correction.partner}</li>
						<li>Bestand: {correction.mailing}</li>
						<li>Datum: {moment(correction.date).format('DD-MM-YYYY')}</li>
						<li>Omschrijving: {correction.description}</li>
					</ul>
				}
			</ModalBody>
			<ModalFooter>
				<Button className="mr-2" color="primary" onClick={handleDelete}>Ja, verwijder correctie</Button>
				<Button color="secondary" onClick={toggle}>Nee</Button>
			</ModalFooter>
		</Modal>
	);
}
