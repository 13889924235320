import React, { Fragment } from 'react';
import { Table, Button } from 'reactstrap';
import moment from 'moment';
import NumberCol from './NumberCol';

export default function Statistics({ mailings, selection, format, admin, getCsv }) {
	return <Fragment>
		<p><Button className="omg-btn blue" onClick={e => {
			e.preventDefault();
			getCsv('stats');
		}}>Download (CSV)</Button></p>
		<Table>
			<thead className="sticky-thead">
				<tr>
					{admin && <th>Partner</th>}
					<th>Campagne</th>
					<th>Verzending</th>
					<th>Bestand</th>
					<th>Datum</th>
					<th className="text-right">Niet verzonden</th>
					<th className="text-right">Verzonden</th>
					<th className="text-right">Hard bounced</th>
					<th className="text-right">Soft bounced</th>
					<th className="text-right">Totaal bounced</th>
					<th className="text-right">Afgeleverd</th>
					<th className="text-right">Geopend</th>
					<th className="text-right">Geklikt</th>
				</tr>
			</thead>
			{selection.length > 0 && <tbody>
				{selection.map(line => {
					return <tr key={line.id}>
						{admin && <td>{line.partner}</td>}
						<td>{line.campaign}</td>
						<td>{line.mailing}</td>
						<td>{line.dataset.name}</td>
						<td>{moment(line.date).format('DD-MM-YYYY')}</td>
						<NumberCol format={format} value={line.rejected} percentage />
						<NumberCol format={format} value={line.sent} percentage />
						<NumberCol format={format} value={line.hardBounced} percentage />
						<NumberCol format={format} value={line.softBounced} percentage />
						<NumberCol format={format} value={line.bounced} percentage />
						<NumberCol format={format} value={line.delivered} percentage />
						<NumberCol format={format} value={line.opened} percentage />
						<NumberCol format={format} value={line.clicked} percentage />
					</tr>;
				})}
			</tbody>}
		</Table>
		{mailings ?
			selection.length === 0 && <p>Geen verzendingen gevonden</p>
			: <p>Loading...</p>}
	</Fragment>;
}
