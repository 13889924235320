import React, { Fragment } from 'react';
import { Table, Button } from 'reactstrap';
import moment from 'moment';
import NumberCol from './NumberCol';
import CorrectionRows from './correction_addons/CorrectionRows';

export default function Mailings({ mailings, corrections, selection, corrSelection, format, PRECISION, admin, getCsv }) {
	const total = { total: 0, rejected: 0, hardBounced: 0, softBounced: 0, sent: 0, sold: 0, net: 0, gross: 0 };
	return <Fragment>
		<p><Button className="omg-btn blue" onClick={e => {
			e.preventDefault();
			getCsv('filtered');
		}}>Download (CSV)</Button></p>
		<Table>
			<thead className="sticky-thead">
				<tr>
					{admin && <th>Partner</th>}
					<th>Campagne</th>
					<th>Verzending</th>
					<th>Bestand</th>
					<th>Datum</th>
					<th className="text-right">Inzet</th>
					<th className="text-right">Rejected</th>
					<th className="text-right">Hard bounced</th>
					<th className="text-right">Soft bounced</th>
					<th className="text-right">Effectief</th>
					<th className="text-right">Factor</th>
					<th className="text-right">Verkocht</th>
					<th className="text-right">Adresprijs</th>
					<th className="text-right">Bruto omzet</th>
					<th className="text-right">Commissie</th>
					<th className="text-right">Netto omzet</th>
				</tr>
			</thead>
			<tbody>
				{
					selection.length > 0 ?
						<Fragment>
							{
								selection.map(line => {
									Object.keys(total).forEach(key => {
										total[key] += line[key];
									});
									return <tr key={line.id}>
										{admin && <td>{line.partner}</td>}
										<td>{line.campaign}</td>
										<td>{line.mailing}</td>
										<td>{line.dataset.name}</td>
										<td className="text-nowrap">{moment(line.date).format('DD-MM-YYYY')}</td>
										<NumberCol format={format} value={line.total} />
										<NumberCol format={format} value={line.rejected} />
										<NumberCol format={format} value={line.hardBounced} />
										<NumberCol format={format} value={line.softBounced} />
										<NumberCol format={format} value={line.sent} />
										<NumberCol format={format} value={line.factor} />
										<NumberCol format={format} value={line.sold} />
										<NumberCol format={format} value={line.price} currency />
										<NumberCol format={format} value={line.gross / PRECISION} currency />
										<NumberCol format={format} value={line.commission} percentage />
										<NumberCol format={format} value={line.net / PRECISION} currency />
									</tr>;
								})
							}
							<tr>
								{admin && <td/>}
								<th className="text-right" colSpan={4}>Total</th>
								<NumberCol format={format} value={total.total} />
								<NumberCol format={format} value={total.rejected} />
								<NumberCol format={format} value={total.hardBounced} />
								<NumberCol format={format} value={total.softBounced} />
								<NumberCol format={format} value={total.sent} />
								<NumberCol format={format} value={total.sold} colSpan={2} />
								<NumberCol format={format} value={total.gross / PRECISION} currency colSpan={2} />
								<NumberCol format={format} value={total.net / PRECISION} currency colSpan={2} />
							</tr>
						</Fragment> 
						:
						<tr>
							<td colSpan="3">{mailings ? 'Geen verzendingen gevonden' : 'Loading...'}</td>
						</tr>
				}
				<CorrectionRows grossSpan={9} netSpan={2} admin={admin} selection={selection} corrections={corrections} corrSelection={corrSelection} format={format} PRECISION={PRECISION}/>
			</tbody>
		</Table>
	</Fragment>;
}
