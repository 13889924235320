import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Login from './Login';
import MailingController from './MailingController';
import Mailings from './Mailings';
import Datasets from './Datasets';
import Statistics from './Statistics';
import Finances from './Finances';
import Contact from './Contact';
import Invoicing from './Invoicing';
import Corrections from './corrections/Corrections';
import NewCorrection from './corrections/NewCorrection';
import EditCorrection from './corrections/EditCorrection';
import PasswordReset from './PasswordReset';
import { getMailings, getFinances, getStats, getInvoicing, getCorrections } from '../api';
import { useMailingFilter } from './MailingFilter';

function renderController(key, admin, child, fetch, title, [filter, setFilter], showCorrections = false) {
	return () => <MailingController key={key} admin={admin} child={child} getMailings={fetch} title={title} showCorrections={showCorrections} filter={filter} setFilter={setFilter} />;
}

function Content({ user, location }) {
	const mFilter = useMailingFilter();
	const sFilter = useMailingFilter();
	const fFilter = useMailingFilter();
	const iFilter = useMailingFilter();
	const cFilter = useMailingFilter();
	if(!user) {
		return <Switch>
			<Route path="/reset/:token">
				<PasswordReset />
			</Route>
			<Route>
				<Login admin={location.pathname === '/admin'} />
			</Route>
		</Switch>;
	}
	const { admin } = user;
	return <Switch>
		{admin && <Route path="/mailings" render={renderController('m', admin, Mailings, getMailings, 'Financieel overzicht intern', mFilter, true)} />}
		<Route path="/stats" render={renderController('s', admin, Statistics, getStats, 'Statistieken', sFilter)} />
		<Route path="/finances" render={renderController('f', admin, Finances, getFinances, 'Financieel overzicht', fFilter, true)} />
		{admin && <Route path="/invoicing" render={renderController('i', admin, Invoicing, getInvoicing, 'Facturatie', iFilter)} />}
		{admin && <Route path="/corrections/new"> <NewCorrection /> </Route>}
		{admin && <Route path="/corrections/:id"> <EditCorrection /> </Route>}
		{admin && <Route path="/corrections" render={renderController('c', admin, Corrections, getCorrections, 'Correcties', cFilter)} />}
		{!admin && <Route path="/contact" component={Contact} />}
		<Route render={() => <Datasets admin={admin} />} />
	</Switch>;
}

export default withRouter(connect(({ user }) => {
	return { user };
})(Content));
