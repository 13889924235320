export function sumColumn(prop, sel) {
	return sel.reduce((acc, curr) => acc + curr[prop], 0);
}

export function ciSort(lhs, rhs) {
	const l = lhs.toLowerCase();
	const r = rhs.toLowerCase();
	if(l > r) {
		return 1;
	} else if(l < r) {
		return -1;
	}
	return 0;
}
