import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Input, Alert } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { checkToken, resetPassword } from '../api';

export default function PasswordReset() {
	const { token } = useParams();
	const [state, setState] = useState(null);
	const [error, setError] = useState(null);
	const [password, setPassword] = useState('');
	useEffect(() => {
		checkToken(token).then(() => {
			setState('valid');
		}).catch(err => {
			setError(err.response?.data?.message || err.message);
		});
	}, [token]);
	function reset(e) {
		e.preventDefault();
		setError(null);
		resetPassword(token, password).then(() => {
			setState('changed');
		}).catch(err => {
			setError(err.response?.data?.message || err.message);
		});
	}
	return <section className="container">
		<h2>Reset Password</h2>
		{error && <Alert color="danger">{error}</Alert>}
		{state === 'valid' && <Form onSubmit={reset}>
			<FormGroup>
				<Input placeholder="Password" value={password} type="password" onChange={e => setPassword(e.target.value)} required />
			</FormGroup>
			<Input type="submit" value="Reset" className="btn omg-btn blue" />
		</Form>}
		{state === 'changed' && <>
			<Alert color="success">Your password has been reset.</Alert>
			<p><Link to="/">Login</Link></p>
		</>}
	</section>;
}
