import React, { useEffect, useState } from 'react';
import CorrectionForm from './CorrectionForm';
import { useHistory, useParams } from 'react-router-dom';
import { getCorrectionOptions, updateCorrection, getCorrection } from '../../api';

export default function EditCorrection() {
	const [datasets, setDatasets] = useState([]);
	const [partners, setPartners] = useState([]);
	const [mailings, setMailings] = useState(['']);
	const [correction, setCorrection] = useState(null);
	const [error, setError] = useState(false);
	const [submitError, setSubmitError] = useState(false);

	const history = useHistory();

	const { id } = useParams();

	const filter = p => {
		if(p === ''){
			return setMailings(['']);
		}
		setMailings([''].concat(datasets[p]));
	};

	useEffect(() => {
		getCorrectionOptions()
			.then(({datasets: ds}) => {
				setDatasets(ds);
				setPartners([''].concat(Object.keys(ds)));
				
				return getCorrection(id)
					.then(corr => {
						setMailings([''].concat(ds[corr.partner]));
						setCorrection({...corr, date: new Date(corr.date)});
					});
			})
			.catch(setError);
	}, [id]);

	const handleSubmit = (e, corr) => {
		e.preventDefault();
		updateCorrection(id, corr)
			.then(() => {
				history.push('/corrections');
			})
			.catch(setSubmitError);
	};

	if(correction){
		return <CorrectionForm 
			title="Correctie Aanpassen"
			partners={partners} 
			mailings={mailings} 
			filter={filter} 
			correction={correction}
			setCorrection={setCorrection}
			handleSubmit={handleSubmit}
			error={error}
			setError={setError}
			submitError={submitError}
		/>;
	}

	return <p>Loading...</p>;
}
