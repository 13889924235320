import React, { Component } from 'react';
import { getCorrections, getFilters } from '../api';
import 'react-datepicker/dist/react-datepicker.css';
import MailingFilter from './MailingFilter';
import vars from '../utils/variables';
import { ciSort } from '../utils/utils';

const currencyFormat = new Intl.NumberFormat(['nl-NL', 'nl', 'en'], {
	style: 'currency',
	currency: 'EUR'
});
const numberFormat = new Intl.NumberFormat(['nl-NL', 'nl', 'en']);
const format = {
	currency(...args) {
		return currencyFormat.format(...args);
	},
	number(...args) {
		return numberFormat.format(...args);
	}
};

function sortByDate (data) {
	data.forEach(d => {
		d.date = new Date(d.date);
	});
	return data.sort((lhs, rhs) => lhs.date - rhs.date);
}

export default class MailingController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mailings: null,
			corrections: null,
			selection: [],
			corrSelection: [],
			relations: {},
			mailingRange: {}
		};
		this.filter = this.filter.bind(this);
	}

	componentDidMount() {
		this.componentDidUpdate({});
		getFilters().then(filters => {
			const datasets = {};
			const partners = {};
			let relations = {};

			if(Array.isArray(filters.datasets)) {
				// non-admin will receive array of bestanden
				filters.datasets.sort(ciSort).forEach(name => {
					datasets[name] = true;
				});
			}else {
				// admin will receive object of partners with bestanden
				Object.keys(filters.datasets).sort(ciSort).forEach(id => {
					partners[id] = true;
				});

				// put all datasets in a list
				const datasetList = [];
				Object.keys(filters.datasets).forEach(id => {
					filters.datasets[id].forEach(ds => {
						datasetList.push(ds);
					});
				});

				// set them all in the datasets object and set them to true
				datasetList.sort(ciSort).forEach(name => {
					datasets[name] = true;
				});

				relations = filters.datasets;
			}
			let counter = 0;
			const join = () => {
				if(++counter > 1) {
					this.filter();
				}
			};
			this.props.setFilter({ datasets, partners }, join);
			this.setState({ relations }, join);
		}).catch(err => {
			console.error('Failed to get filters', err);
		});
	}

	componentDidUpdate(props) {
		if(props.getMailings !== this.props.getMailings) {
			this.getMailings(this.filter);
		}
	}

	getMailings(cb) {
		const { start, end } = this.props.filter;
		this.setState({ mailings: null, selection: [] });
		const getInfo = [this.props.getMailings];
		if(this.props.showCorrections){
			getInfo.push(getCorrections);
		}
		Promise.all(getInfo.map(i => i(start, end))).then(([mailings, corrections]) => {
			if(corrections){
				corrections = sortByDate(corrections);
			}
			this.setState({
				mailings: sortByDate(mailings),
				corrections,
				selection: [],
				corrSelection: [],
				mailingRange: { start, end }
			}, cb);
		}).catch(err => console.error('Failed to load mailings', err));
	}

	filter(e) {
		if(e && e.preventDefault) {
			e.preventDefault();
		}
		const { mailings, mailingRange } = this.state;
		const { admin, showCorrections, filter } = this.props;
		const { datasets, partners, start, end } = filter;
		if(mailings) {
			const f = () => {
				const selection = this.state.mailings.filter(mailing => {
					return datasets[mailing.dataset.name] && (!admin || partners[mailing.partner]);
				});
				let corrSelection = [];
				if(showCorrections && this.state.corrections){
					corrSelection = this.state.corrections.filter(correction => {
						return datasets[correction.dataset.name] && (!admin || partners[correction.partner]);
					});
				}
				this.setState({ selection, corrSelection });
			};
			if(mailingRange.start !== start || mailingRange.end !== end) {
				this.getMailings(f);
			} else {
				f();
			}
		}
	}

	getCsv = path => {
		const { filter } = this.props;
		const form = document.createElement('form');
		form.method = 'post';
		form.action = `/api/mailings/csv/${path}`;
		document.body.append(form);
		['start', 'end'].forEach(key => {
			const input = document.createElement('input');
			input.value = filter[key].toISOString();
			input.type = 'hidden';
			input.name = key;
			input.hidden = true;
			form.appendChild(input);
		});
		['datasets', 'partners'].forEach(key => {
			const input = document.createElement('input');
			const values = filter[key];
			input.value = JSON.stringify(Object.keys(values).filter(v => values[v]));
			input.type = 'hidden';
			input.name = key;
			input.hidden = true;
			form.appendChild(input);
		});
		form.submit();
		document.body.removeChild(form);
	};

	render() {
		const { mailings, corrections, selection, corrSelection, relations } = this.state;
		const { child: Child, admin, title, filter, setFilter } = this.props;
		return <section className="container-fluid">
			<MailingFilter admin={admin} title={title} mailings={mailings} relations={relations} onSubmit={this.filter} state={filter} setState={setFilter} />
			<Child
				mailings={mailings}
				selection={selection}
				corrections={corrections}
				corrSelection={corrSelection}
				format={format}
				PRECISION={vars.PRECISION}
				admin={admin}
				getCsv={this.getCsv}
				reload={() => this.getMailings(this.filter)}
			/>
		</section>;
	}
}
