import React, { useState, Fragment } from 'react';
import { Input, InputGroup, InputGroupAddon, Row, Col, Collapse, Button } from 'reactstrap';

function Filters({ values, onChange, placeholder }) {
	const [query, setQuery] = useState('');
	const [open, setOpen] = useState(false);
	const cols = [null, null, null];
	let keys = Object.keys(values);
	const allSelected = keys.length > 0? keys.every(key => values[key]) : false;
	const step = Math.ceil(keys.length / cols.length);

	const handleToggleAll = e => {
		const copy = {...values};
		Object.keys(copy).forEach(key => {
			copy[key] = e.target.checked;
		});
		onChange(copy);
	};

	const toggleDD = e => {
		e.preventDefault();
		setOpen(!open);
	};

	const check = (e, key) => {
		onChange({ [key]: e.target.checked });
	};

	const handleQuery = e => {
		if(e.target.value) {
			const lower = e.target.value.toLowerCase().trim();
			if(lower) {
				keys = keys.filter(k => k.toLowerCase().includes(lower));
				// copy the values
				const valuesCopy = {...values};

				// set all keys that were not in search string to false, everything else to true
				Object.keys(valuesCopy).forEach(id => {
					if(keys.includes(id)){
						valuesCopy[id] = true;
					}else {
						valuesCopy[id] = false;
					}
				});

				onChange(valuesCopy);
			}
		}else {
			// if nothing is filtered, set all to true
			handleToggleAll({ target: { checked: true }});
		}

		setQuery(e.target.value);
	};

	return <Fragment>
		<Row className="mb-1 mt-2">
			<Col md={4} sm={6}>
				<h4>
					<label>
						<input type="checkbox" checked={allSelected} onChange={handleToggleAll} /> {placeholder}
					</label>
				</h4>
			</Col>

			<Col md={8} sm={6}>
				<InputGroup>
					<InputGroupAddon addonType="prepend" className={open ? 'dropup' : ''}>
						<Button className="dropdown-toggle omg-btn purple" disabled={!!query} onClick={toggleDD}></Button>
					</InputGroupAddon>
					<Input placeholder="Filter" onChange={handleQuery} />
				</InputGroup>
			</Col>
		</Row>

		<Collapse isOpen={open || !!query}>
			<Row className="small text-break">
				{cols.map((_, i) => {
					return <Col key={i} md={4}>
						{keys.slice(i * step, (i + 1) * step).map(key => {
							return <label key={key} className="d-block">
								<input type="checkbox" checked={values[key]} onChange={e => check(e, key)} /> {key}
							</label>;
						})}
					</Col>;
				})}
				<Col md={4}>
				</Col>
				<Col md={4}>
				</Col>
				<Col md={4}>
				</Col>
			</Row>
		</Collapse>
	</Fragment>;
}

export default Filters;
