import React from 'react';
import { Container } from 'reactstrap';

export default function Contact(){
	return <Container>
		<h2 className="mb-5">Contact</h2>
		<p>
			OMG/Mailmedia <br />
			Zonnebaan 45 <br />
			3542EB Utrecht <br />
			Telefoon +31(0)30 750 4000 <br />
		</p>

		<p>
			Het kantoor is gevestigd aan de A2 - ring west Utrecht. Parkeren voor het kantoor is geen probleem.
		</p>
	</Container>;
}
