import React, { Fragment } from 'react';
import { Table, Button } from 'reactstrap';
import moment from 'moment';
import NumberCol from './NumberCol';
import CorrectionRows from './correction_addons/CorrectionRows';

export default function Finances({ mailings, corrections, selection, corrSelection, format, admin, getCsv, PRECISION }) {
	const total = { sold: 0, net: 0, gross: 0 };
	return <Fragment>
		<p><Button className="omg-btn blue" onClick={e => {
			e.preventDefault();
			getCsv('finances');
		}}>Download (CSV)</Button></p>
		<Table>
			<thead className="sticky-thead">
				<tr>
					{admin && <th>Partner</th>}
					<th>Campagne</th>
					<th>Verzending</th>
					<th>Bestand</th>
					<th>Datum</th>
					<th className="text-right">Verkocht</th>
					<th className="text-right">Adresprijs</th>
					<th className="text-right">Bruto omzet</th>
					<th className="text-right">Netto omzet</th>
				</tr>
			</thead>
			<tbody>
				{
					selection.length > 0 ? 
						<Fragment>
							{selection.map(line => {
								Object.keys(total).forEach(key => {
									total[key] += line[key];
								});
								return <tr key={line.id}>
									{admin && <td>{line.partner}</td>}
									<td>{line.campaign}</td>
									<td>{line.mailing}</td>
									<td>{line.dataset.name}</td>
									<td>{moment(line.date).format('DD-MM-YYYY')}</td>
									<NumberCol format={format} value={line.sold} />
									<NumberCol format={format} value={line.price} currency />
									<NumberCol format={format} value={line.gross / PRECISION} currency />
									<NumberCol format={format} value={line.net / PRECISION} currency />
								</tr>;
							})}
						
							<tr>
								{admin && <td/>}
								<th className="text-right" colSpan={4}>Totaal</th>
								<NumberCol format={format} value={total.sold} />
								<NumberCol format={format} value={total.gross / PRECISION} currency colSpan={2} />
								<NumberCol format={format} value={total.net / PRECISION} currency />
							</tr>
						</Fragment>
						:
						<tr> <td colSpan="3">{mailings ? 'Geen verzendingen gevonden' : 'Loading...'}</td> </tr>
				}
				<CorrectionRows grossSpan={3} netSpan={1} admin={admin} selection={selection} corrections={corrections} corrSelection={corrSelection} format={format} PRECISION={PRECISION}/>
			</tbody>
		</Table>
	</Fragment>;
}
