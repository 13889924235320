import { createStore, combineReducers } from 'redux';

function user(state = null, action) {
	if(action.type === 'login') {
		return action.account;
	}
	return state;
}

export default createStore(combineReducers({
	user
}));
