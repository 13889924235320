import React, { Fragment, useState } from 'react';
import { Table, Button } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import DeleteModal from './DeleteModal';
import { deleteCorrection } from '../../api';
import Alert from 'reactstrap/lib/Alert';
import NumberCol from '../NumberCol';
import { sumColumn } from '../../utils/utils';

export default function Corrections({ mailings: corrections, selection, reload, format, PRECISION }) {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [corrToDelete, setCorrToDelete] = useState(null);
	const [error, setError] = useState(null);
	const history = useHistory();

	const toggleModal = (corr=null) => {
		setModalIsOpen(!modalIsOpen);
		setCorrToDelete(corr);
	};

	const handleDelete = () => {
		setModalIsOpen(!modalIsOpen);
		deleteCorrection(corrToDelete._id)
			.then(reload)
			.catch(setError);
	};

	return <Fragment>
		<DeleteModal correction={corrToDelete} handleDelete={handleDelete} isOpen={modalIsOpen} toggle={toggleModal} />
		<p><Button className="omg-btn blue" onClick={e => {
			e.preventDefault();
			history.push('/corrections/new');
		}}>Nieuwe Correctie</Button></p>
		<Alert isOpen={error}>
			Er is iets misgegaan tijdens het verwijderen van de correctie, probeer het later nog een keer.
		</Alert>
		<Table>
			<thead className="sticky-thead">
				<tr>
					<th>Omschrijving</th>
					<th>Partner</th>
					<th>Bestand</th>
					<th>Datum</th>
					<th className="text-right">Bruto</th>
					<th className="text-right">Netto</th>
					<th/>
				</tr>
			</thead>
			{selection.length > 0 && <tbody>
				{selection.map((line, index) => {
					return <tr key={index}>
						<td>{line.description}</td>
						<td>{line.partner}</td>
						<td>{line.dataset.name}</td>
						<td>{moment(line.date).format('DD-MM-YYYY')}</td>
						<NumberCol format={format} value={line.gross / PRECISION} currency/>
						<NumberCol format={format} value={line.net / PRECISION} currency/>
						<td>
							<div className="d-flex flex-row justify-content-around">
								<Link className="btn omg-btn blue font-weight-bold" to={`/corrections/${line._id}`}>Edit</Link>
								<Button className="omg-btn red font-weight-bold" onClick={() => toggleModal(line)}> Delete </Button>
							</div>
						</td>
					</tr>;
				})}
				<tr>
					<th colSpan={4}>Totaal</th>
					<NumberCol className="font-weight-bold text-right" format={format} value={sumColumn('gross', selection) / PRECISION} currency />
					<NumberCol className="font-weight-bold text-right" format={format} value={sumColumn('net', selection) / PRECISION} currency />
					<td/>
				</tr>
			</tbody>}
		</Table>
		{corrections ?
			selection.length === 0 && <p>Geen correcties gevonden</p>
			: <p>Loading...</p>}
	</Fragment>;
}
