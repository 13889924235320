import React, { useEffect, useState } from 'react';
import { getCorrectionOptions, saveCorrection } from '../../api';
import CorrectionForm from './CorrectionForm';
import { useHistory } from 'react-router-dom';

const defaultCorr = {
	partner: '',
	dataset: '',
	date: new Date(),
	gross: 0,
	net: 0,
	description: ''
};

export default function NewCorrection() {
	const [datasets, setDatasets] = useState([]);
	const [partners, setPartners] = useState([]);
	const [mailings, setMailings] = useState(['']);
	const [newCorrection, setNewCorrection] = useState(defaultCorr);
	const [error, setError] = useState(false);
	const [submitError, setSubmitError] = useState(false);


	const history = useHistory();

	useEffect(() => {
		getCorrectionOptions()
			.then(({datasets: data}) => {
				setDatasets(data);
				setPartners([''].concat(Object.keys(data)));
			})
			.catch(setError);
	}, []);

	const filter = p => {
		if(p === ''){
			setMailings(['']);
		}else {
			setMailings([''].concat(datasets[p]));
		}
	};

	const handleSubmit = (e, corr) => {
		e.preventDefault();
		saveCorrection(corr)
			.then(() => {
				history.push('/corrections');
			})
			.catch(setSubmitError);
	};

	return <CorrectionForm 
		title="Nieuwe Correctie"
		partners={partners} 
		mailings={mailings} 
		filter={filter} 
		handleSubmit={handleSubmit}
		error={error}
		setError={setError}
		submitError={submitError}
		correction={newCorrection}
		setCorrection={setNewCorrection}
	/>;
}
