import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Filters from './Filters';
import moment from 'moment';
import { ciSort } from '../utils/utils';

function buildUpdate(partners, relations, options) {
	const newDataSets = [];
	// state doesnt know what we uncheck yet, just make the new state here
	const newPartnersState = {...partners, ...options};

	Object.keys(newPartnersState).forEach(partner => {
		// rebuild the datasets, only use the datasets that have partner selected
		if(newPartnersState[partner]){
			newDataSets.push(...relations[partner]);
		}
	});

	// sort them again
	const sortedDatasets = {};
	newDataSets.sort(ciSort).forEach(name => {
		sortedDatasets[name] = true;
	});
	return {
		partners: newPartnersState,
		datasets: sortedDatasets
	};
}

function buildDefaultState() {
	return {
		start: new Date(moment().startOf('month').subtract(1, 'months')),
		end: new Date(moment().endOf('month').subtract(1, 'months')),
		datasets: {},
		partners: {}
	};
}

function runCallbacks(callbacks, setCallbacks) {
	if(callbacks.length) {
		callbacks.forEach(cb => {
			try {
				return cb();
			} catch(ignored) {
				//do nothing
			}
		});
		setCallbacks([]);
	}
}

function updateState(setState, setCallbacks) {
	return (u, cb) => setState(s => {
		if(cb) {
			setCallbacks(cbs => cbs.concat(cb));
		}
		return { ...s, ...u };
	});
}

export function useMailingFilter() {
	const [state, setState] = useState(buildDefaultState);
	const [callbacks, setCallbacks] = useState([]);
	useEffect(() => {
		runCallbacks(callbacks, setCallbacks);
	}, [callbacks]);
	return [state, updateState(setState, setCallbacks)];
}

export default function MailingFilter({ admin, title, mailings, relations, onSubmit, state, setState }) {
	const { start, end, datasets, partners } = state;
	return <Form className="mb-3" onSubmit={onSubmit}>
		<div className="form-inline justify-content-between">
			<h2>{title}</h2>
			<div className="d-flex justify-content-end">
				<FormGroup className="mr-1">
					<DatePicker dateFormat="dd-MM-yyyy" className="form-control" selected={start} onChange={d => setState({ start: d })} />
				</FormGroup>
				<FormGroup className="mr-1">
					<DatePicker dateFormat="dd-MM-yyyy" className="form-control" selected={end} onChange={d => setState({ end: d })} />
				</FormGroup>
				<FormGroup>
					<Input value="Filter" type="submit" className="btn omg-btn red" disabled={!mailings} />
				</FormGroup>
			</div>
		</div>
		{admin && <Filters values={partners} onChange={options => {
			setState(buildUpdate(partners, relations, options));
		}} placeholder="Partners" />}
		<Filters values={datasets} onChange={options => {
			setState({ datasets: { ...datasets, ...options } });
		}} placeholder="Bestanden" />
	</Form>;
}
