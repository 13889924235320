import React, { Component } from 'react';
import { Table, Form, FormGroup, Input, Col, Alert } from 'reactstrap';
import { getDatasets } from '../api';

function includes(value, query) {
	if(!value) {
		return false;
	}
	return value.toLowerCase().includes(query);
}

function Data({ admin, datasets, hits, error }) {
	if(error) {
		return <Alert color="danger">{error}</Alert>;
	} else if(!datasets) {
		return <p>Loading...</p>;
	} else if(!datasets.length) {
		return <p>Geen bestanden gevonden</p>;
	}
	return <Table>
		<thead className="sticky-thead">
			<tr>
				{admin && <th>Partner</th>}
				<th>Naam</th>
				<th>API Key</th>
				<th className="text-right">Emails</th>
			</tr>
		</thead>
		<tbody>
			{
				hits.map(dataset => {
					return <tr key={dataset.key}>
						{admin && <td>{dataset.partner}</td>}
						<td>{dataset.name}</td>
						<td><code>{dataset.key}</code></td>
						<td className="text-right">{dataset.recordCount}</td>
					</tr>;
				})
			}
		</tbody>
	</Table>;
}

export default class Datasets extends Component {
	constructor(props) {
		super(props);
		this.state = {
			datasets: null,
			search: '',
			error: null
		};
	}

	componentDidMount() {
		getDatasets().then(datasets => {
			this.setState({ datasets });
		}).catch(err => {
			this.setState({ error: err.message });
			console.error('Failed to load datasets', err);
		});
	}

	render() {
		const { datasets, search, error } = this.state;
		const { admin } = this.props;
		const query = search.trim().toLowerCase();
		const hits = datasets && (query ? datasets.filter(dataset => includes(dataset.partner, query) || includes(dataset.name, query) || includes(dataset.description, query)) : datasets);
		return <section className="container-fluid">
			<h2 className="mb-5">Bestanden</h2>
			<Col lg={3} className="px-0">
				<Form>
					<FormGroup>
						<Input maxLength={50} placeholder="Zoek bestanden" value={search} type="text" onChange={e => this.setState({search: e.target.value})} />
					</FormGroup>
				</Form>
			</Col>
			<Data admin={admin} datasets={datasets} hits={hits} error={error} />
			{datasets && !hits.length && <p>Geen bestanden gevonden voor zoekopdracht: {search}</p>}
		</section>;
	}
}
