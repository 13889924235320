import React from 'react';
import vars from '../utils/variables';

export default function NumberCol(props) {
	const p = Object.assign({ className: 'text-right' }, props);
	const { format, value, currency, percentage } = props;
	delete p.format;
	delete p.value;
	delete p.currency;
	delete p.percentage;
	if(currency) {
		p.children = format.currency(value);
	} else if(percentage) {
		p.children = `${format.number(value * vars.PERCENT)}%`;
	} else {
		p.children = format.number(value);
	}
	return <td {...p} />;
}
